import revive_payload_client_XPTcmQGTHkpWoM_8NtRkwA60nk_dtWwVygla_3OFwIs from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GNTy8aYV9Jow_AOuMxm6fcsI234L8a2syx8_SRoEEDQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xbDFQxEKPzCK1jcXi2BiK7q5uQInm5AZ9ZmHfhjw_EQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hGgmHJHomlytIuYtTw35md5jVU3UnPUDLpz5dhzcfoE from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VFX_f7dtRDnJGAOqM6EEgoa_9_oLJn4F_g6pONUjrIA from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qMtgdjdeB8teuLJ9Rnd81aqxEVXJ8_Wr0kP3denmCj0 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rg6FEV_dkNnX73sIUskKJ1MFRJ0fNqYhZ9YrvarR2tw from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_6k3BkZfrljv_Ikf7PDDn_tx5EN7En3nkIEJPKymS1JM from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.27_db0@0.3.1_encoding@0.1.13_ioredi_10179d20bf32ee2107d58082235c78fc/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/app/plugins/gtm.client.ts";
import sentry_client_MJTLNmRW1o4nGDwwmnW8ZOFOAcwoUzwlFoet6n_LfqQ from "/app/plugins/sentry.client.js";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_XPTcmQGTHkpWoM_8NtRkwA60nk_dtWwVygla_3OFwIs,
  unhead_GNTy8aYV9Jow_AOuMxm6fcsI234L8a2syx8_SRoEEDQ,
  router_xbDFQxEKPzCK1jcXi2BiK7q5uQInm5AZ9ZmHfhjw_EQ,
  payload_client_hGgmHJHomlytIuYtTw35md5jVU3UnPUDLpz5dhzcfoE,
  navigation_repaint_client_VFX_f7dtRDnJGAOqM6EEgoa_9_oLJn4F_g6pONUjrIA,
  check_outdated_build_client_qMtgdjdeB8teuLJ9Rnd81aqxEVXJ8_Wr0kP3denmCj0,
  chunk_reload_client_rg6FEV_dkNnX73sIUskKJ1MFRJ0fNqYhZ9YrvarR2tw,
  plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_6k3BkZfrljv_Ikf7PDDn_tx5EN7En3nkIEJPKymS1JM,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  sentry_client_MJTLNmRW1o4nGDwwmnW8ZOFOAcwoUzwlFoet6n_LfqQ,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds
]