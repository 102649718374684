
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as indexMod7jomykNw_45aIqJwFoi16OhHzPTxcVNNL10OzzgoocMeta } from "/app/pages/quote/index.vue?macro=true";
import { default as privacy_45policyzgG_45S68_451GHrVnCshLKLsmg43yjC4fB7Q3UU1pkWXTMMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as terms_45conditionszuLNpQmRCZaAKKRTh424JrqP0JiuwjDSq8bLR7U_wOcMeta } from "/app/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "quote",
    path: "/quote",
    meta: indexMod7jomykNw_45aIqJwFoi16OhHzPTxcVNNL10OzzgoocMeta || {},
    component: () => import("/app/pages/quote/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyzgG_45S68_451GHrVnCshLKLsmg43yjC4fB7Q3UU1pkWXTMMeta || {},
    component: () => import("/app/pages/privacy-policy.vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionszuLNpQmRCZaAKKRTh424JrqP0JiuwjDSq8bLR7U_wOcMeta || {},
    component: () => import("/app/pages/terms-conditions.vue")
  }
]